import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.48 213.37">
    <path d="M31.82,105.75l1.07,12.4q10.94-14.36,30.18-14.35,16.5,0,24.65,9.86t8.45,29.69v68.06h-33V144.72q0-8-3.22-11.77t-11.72-3.76q-9.66,0-14.35,7.62v74.6H1V105.75Z" />
    <path d="M212.48,159.46q0,26-10.74,39.94t-30.27,14a31.22,31.22,0,0,1-26-12.8l-1.37,10.84H114.63v-150h32.91v52.84q9.18-10.45,23.73-10.45,19.72,0,30.47,14.16t10.74,39.84Zm-33-2.1q0-15.2-4.05-21.69T163,129.19q-11.13,0-15.43,8.48v41.92q4.2,8.39,15.62,8.39,11.63,0,14.75-11.31Q179.47,171.11,179.47,157.36Z" />
    <line
      x1="212"
      y1="15"
      y2="15"
      fill="none"
      stroke="#f48c3d"
      strokeMiterlimit="10"
      strokeWidth="30"
    />
  </svg>
);

export default Logo;
